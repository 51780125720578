import React, { useState, useEffect, useContext } from "react";
import LanguageContext from "@/contexts/language";

interface ContainerProps {
  featuresRef: React.RefObject<HTMLDivElement>;
}

function Second({ featuresRef }: ContainerProps) {
  const { language, setEnglish, setItaliano } = useContext(LanguageContext);
  const [width, setWidth] = useState<number>(0);

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }
  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  return (
    <section
      className="max-w-[67.5rem] mx-auto w-full px-4 py-8 text-center flex flex-col items-center"
      ref={featuresRef}
    >
      {language === "english" ? (
        <h1 className="text-3xl font-semibold mb-5 max-w-2xl md:text-4xl">
          Works with everything! everywhere!
        </h1>
      ) : (
        <h1 className="text-3xl font-semibold mb-5 max-w-2xl md:text-4xl">
          Funziona con tutto! ovunque!
        </h1>
      )}
      {language === "english" ? (
        <p className="text-neutral-500 font-medium mb-10 max-w-3xl md:text-xl">
          Bring your own device, all you need is an internet connection! For
          simultaneous translation, just send an audio signal with the RTMP
          protocol to our servers. Get Soonia now and discover the future of
          events!
        </p>
      ) : (
        <p className="text-neutral-500 font-medium mb-10 max-w-3xl md:text-xl">
          Utilizza il tuo dispositivo personale, tutto ciò di cui hai bisogno è
          una connessione internet! Per la traduzione simultanea basterà inviare
          ai nostri server un segnale audio con protocollo RTMP. Prova subito
          Soonia e scopri il futuro degli eventi!
        </p>
      )}
      <div className="relative mb-16 w-full md:1-10/12 px-0 md:px-12 xl:px-28">
        <img
          src={
            language === "english"
              ? "/images/schemaeng.webp"
              : "/images/schemaita.webp"
          }
          width={1920}
          height={1080}
          alt="three"
          className="rounded-2xl"
        />
      </div>

      <h1 className="text-2xl font-semibold mb-5 max-w-2xl md:text-3xl">
        {language === "english"
          ? "Simultaneous Translation"
          : "Traduzione simultanea"}
      </h1>
      {language === "english" ? (
        <p className="text-neutral-500 font-medium mb-10 max-w-3xl text-lg md:text-xl">
          Handle up to 6 audio channels simultaneously with less than 500ms
          latency. All you have to do is scan a QR code, select the language you
          want to hear, and you&apos;re good to go!
        </p>
      ) : (
        <p className="text-neutral-500 font-medium mb-10 max-w-3xl text-lg md:text-xl">
          Gestisci fino a 6 canali audio contemporaneamente con una latenza
          inferiore ai 500ms. Ti basterà scannerizzare un qr code, scegliere la
          lingua d&apos;ascolto e il gioco è fatto!
        </p>
      )}
      <div className="relative mb-16">
        <img
          src="/images/trad.webp"
          width={1920}
          height={1080}
          alt="three"
          className="rounded-2xl"
        />
      </div>
      <h1 className="text-2xl font-semibold my-5 max-w-2xl md:text-3xl">
        {language === "english" ? "Polls and Feedback" : "Sondaggi e Feedback"}
      </h1>
      {language === "english" ? (
        <p className="text-neutral-500 font-medium max-w-3xl text-lg md:text-xl">
          This feature enables you to design custom surveys and collect feedback
          from your audience. You&apos;ll have access to real-time information
          through a customizable dashboard that can be seamlessly integrated
          with the graphic mixer.
        </p>
      ) : (
        <p className="text-neutral-500 font-medium max-w-3xl text-lg md:text-xl">
          Grazie a questa funzione potrai creare sondaggi e feedback su misura
          utili per recuperare informazioni dal pubblico. Le informazioni, in
          tempo reale, saranno disponibili su una piattaforma personalizzabile
          integrabile con i principali mixer grafici.
        </p>
      )}
      <div className="relative mb-16">
        {language === "english" ? (
          <>
            <img
              src="/images/pollseng.webp"
              width={1920}
              height={1080}
              alt="polls"
              className="rounded-2xl hidden md:block"
            />
            <img
              src="/images/pollsmobileeng.webp"
              width={1920}
              height={1080}
              alt="polls"
              className="rounded-2xl block md:hidden mt-5"
            />
          </>
        ) : (
          <>
            <img
              src="/images/pollsita.webp"
              width={1920}
              height={1080}
              alt="polls"
              className="rounded-2xl hidden md:block"
            />
            <img
              src="/images/pollsmobileita.webp"
              width={1920}
              height={1080}
              alt="polls"
              className="rounded-2xl block md:hidden mt-5"
            />
          </>
        )}
      </div>
      <h1 className="text-2xl font-semibold my-5 max-w-2xl md:text-3xl">
        {language === "english" ? "Lighting Effects" : "Effetti Luminosi"}
      </h1>
      {language === "english" ? (
        <p className="text-neutral-500 font-medium mb-10 max-w-3xl text-lg md:text-xl">
          Transform your events into immersive experiences by using attendee
          devices to create stunning visual choreographies that bring your event
          to life. Our system is fully compatible with the Art-net protocol and
          Madrix software
        </p>
      ) : (
        <p className="text-neutral-500 font-medium mb-10 max-w-3xl text-lg md:text-xl">
          Trasforma i tuoi eventi in esperienze coinvolgenti. Utilizza i
          dispositivi dei partecipanti per creare straordinarie coreografie
          visive che danno vita al tuo evento. Supportiamo il protocollo Art-net
          e il software Madrix.
        </p>
      )}
      <div className="relative px-5 md:px-32">
        <img
          src="/images/LUCINE.webp"
          width={1920}
          height={1080}
          alt="Lighting Effects"
          className="rounded-2xl"
        />
      </div>
    </section>
  );
}

export default Second;
