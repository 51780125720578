import React, { useState, useContext } from "react";
import Menu from "./menu";
import LanguageContext from "@/contexts/language";
import Link from "next/link";
import { useRouter } from "next/router";

function Nav(props: {
  featuresRef?: React.RefObject<HTMLDivElement>;
  emailRef?: React.RefObject<HTMLDivElement>;
}) {
  const { language, setEnglish, setItaliano } = useContext(LanguageContext);
  const [bg, setBg] = useState("");
  const [buttonBg, setButtonBg] = useState("bg-white");
  const [languageBg, setLanguageBg] = useState("bg-white");
  const [menuOpen, setMenuOpen] = useState(false);
  const router = useRouter();

  const handleMenuClose = () => {
    setMenuOpen(false);
  };

  const onScroll = () => {
    const scroll = window.scrollY;
    if (scroll > 0) {
      setBg("bg-white/60");
      setButtonBg("bg-neutral-100/50");
      setLanguageBg("bg-neutral-100/50");
    } else {
      setBg("bg-transparent");
      setButtonBg("bg-white");
      setLanguageBg("bg-white");
    }
  };

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  React.useEffect(() => {
    window.addEventListener("scroll", onScroll);
    return () => window.removeEventListener("scroll", onScroll);
  }, []);

  return (
    <header className="fixed top-3 left-0 right-0 z-20 max-w-[67.5rem] px-4 py-0 mx-auto w-full">
      <nav
        className={`flex items-center h-16 backdrop-blur-xl rounded-full transition-all duration-200 ease-linear max-w-3xl mx-auto ${bg}`}
      >
        <ul className="flex items-center justify-between w-full h-full p-3 gap-2 capitalize">
          <li className="text-center w-10 h-10 relative rounded-full mr-5 self-start">
            <Link href={"/"}>
              <img
                src="/logo.png"
                alt="logo"
                className="w-full h-full rounded-full shadow-sm"
              ></img>
            </Link>
          </li>
          <li className="hidden gap-3 md:flex mr-auto">
            <button
              className="bg-none border-none outline-none text-neutral-600 font-semibold text-lg"
              onClick={() => {
                if (router.pathname !== "/") router.push("/");
                if (props.featuresRef?.current) {
                  const offsetTop = props.featuresRef?.current.offsetTop;
                  window.scrollTo({
                    top: offsetTop - 100,
                    behavior: "smooth",
                  });
                }
              }}
            >
              {language === "english" ? "Features" : "Caratteristiche"}
            </button>
            <button
              className="text-neutral-600 font-semibold text-lg"
              onClick={() => {
                if (router.pathname !== "/") router.push("/");

                if (props.emailRef?.current) {
                  const offsetTop = props.emailRef?.current.offsetTop;
                  window.scrollTo({
                    top: offsetTop - 100,
                    behavior: "smooth",
                  });
                }
              }}
            >
              {language === "english" ? "Join us" : "Unisciti"}
            </button>
            <Link
              href="/pricing"
              className="text-neutral-600 font-semibold text-lg"
            >
              {language === "english" ? "Pricing" : "Prezzi"}
            </Link>
          </li>
          <li className="flex md:hidden self-center">
            <div className={`flex p-1 rounded-2xl`}>
              <div
                className={`flex p-1 rounded-xl justify-center items-center ${
                  language === "english" ? "bg-gray-300" : "bg-none"
                }`}
              >
                <button className="relative h-8 w-12" onClick={setEnglish}>
                  <img
                    src={"/images/en.png"}
                    alt="English flag"
                    className={`rounded-[10px] w-full h-full transition-all duration-100 ease-linear ${
                      language === "english" ? "opacity-100" : "opacity-30"
                    }`}
                  ></img>
                </button>
              </div>
              <div
                className={`flex p-1 rounded-xl justify-center items-center ${
                  language === "italiano" ? "bg-gray-300" : "bg-none"
                }`}
              >
                <button className="relative h-8 w-12" onClick={setItaliano}>
                  <img
                    src={"/images/it.png"}
                    alt="Italian flag"
                    className={`rounded-[10px] w-full h-full transition-all duration-100 ease-linear ${
                      language === "italiano" ? "opacity-100" : "opacity-30"
                    }`}
                  ></img>
                </button>
              </div>
            </div>
          </li>
          <li className="right-0 md:hidden self-end">
            <button
              className="flex p-3 rounded-full outline-none bg-white shadow-sm"
              onClick={toggleMenu}
            >
              <svg width="16" height="16" fill="none" viewBox="0 0 24 24">
                <path
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="1.5"
                  d="M4.75 5.75H19.25"
                ></path>
                <path
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="1.5"
                  d="M4.75 18.25H19.25"
                ></path>
                <path
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="1.5"
                  d="M4.75 12H19.25"
                ></path>
              </svg>
            </button>
            {menuOpen && (
              <Menu
                onClose={handleMenuClose}
                featuresRef={props.featuresRef ? props.featuresRef : undefined}
                emailRef={props.emailRef ? props.emailRef : undefined}
              />
            )}
          </li>
          <li className="hidden md:flex">
            <div className={`flex p-1 rounded-2xl`}>
              <div
                className={`flex p-1 rounded-xl justify-center items-center ${
                  language === "english" ? "bg-gray-300" : "bg-none"
                }`}
              >
                <button className="relative h-8 w-12" onClick={setEnglish}>
                  <img
                    src={"/images/en.png"}
                    alt="English flag"
                    className={`rounded-[10px] w-full h-full transition-all duration-100 ease-linear ${
                      language === "english" ? "opacity-100" : "opacity-30"
                    }`}
                  ></img>
                </button>
              </div>
              <div
                className={`flex p-1 rounded-xl justify-center items-center ${
                  language === "italiano" ? "bg-gray-300" : "bg-none"
                }`}
              >
                <button className="relative h-8 w-12" onClick={setItaliano}>
                  <img
                    src={"/images/it.png"}
                    alt="Italian flag"
                    className={`rounded-[10px] w-full h-full transition-all duration-100 ease-linear ${
                      language === "italiano" ? "opacity-100" : "opacity-30"
                    }`}
                  ></img>
                </button>
              </div>
            </div>
          </li>
        </ul>
      </nav>
    </header>
  );
}

export default Nav;
