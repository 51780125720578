import React, { useEffect, useRef, useState, useContext } from "react";
import ReactDOM from "react-dom";
import Link from "next/link";
import { useRouter } from "next/router";
import LanguageContext from "@/contexts/language";

interface MenuProps {
  onClose: () => void;
}

const Menu = (props: {
  onClose: () => void;
  featuresRef?: React.RefObject<HTMLDivElement>;
  emailRef?: React.RefObject<HTMLDivElement>;
}) => {
  const menuRef = useRef<HTMLDivElement>(null);
  const router = useRouter();
  const { language } = useContext(LanguageContext);

  return ReactDOM.createPortal(
    <>
      <div
        className="menu fixed top-20 right-4 mt-2 flex flex-col gap-1 bg-white/80 rounded-2xl p-1 backdrop-blur-xl"
        ref={menuRef}
      >
        <button
          className="px-4 py-2 bg-white shadow-sm rounded-xl text-neutral-600 font-semibold text-center"
          onClick={() => {
            if (router.pathname !== "/") router.push("/");
            if (props.featuresRef?.current) {
              const offsetTop = props.featuresRef?.current.offsetTop;
              window.scrollTo({
                top: offsetTop - 100,
                behavior: "smooth",
              });
            }
          }}
        >
          {language === "english" ? "Features" : "Caratteristiche"}
        </button>
        <button
          className="px-4 py-2 bg-white shadow-sm rounded-xl text-neutral-600 font-semibold text-center"
          onClick={() => {
            if (router.pathname !== "/") router.push("/");

            if (props.emailRef?.current) {
              const offsetTop = props.emailRef?.current.offsetTop;
              window.scrollTo({
                top: offsetTop - 100,
                behavior: "smooth",
              });
            }
          }}
        >
          {language === "english" ? "Join us" : "Unisciti"}
        </button>
        <Link
          href={"/pricing"}
          className="px-4 py-2 bg-white shadow-sm rounded-xl text-neutral-600 font-semibold text-center"
        >
          {language === "english" ? "Pricing" : "Prezzi"}
        </Link>
      </div>
    </>,
    document.body
  );
};

export default Menu;
