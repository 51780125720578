import React, { useContext, useRef } from "react";
import Image from "next/image";
import LanguageContext from "@/contexts/language";

function Container(props: { featuresRef: React.RefObject<HTMLDivElement> }) {
  const { language, setEnglish, setItaliano } = useContext(LanguageContext);
  const videoRef = useRef<HTMLVideoElement>(null);

  return (
    <section className="relative max-w-[67.5rem] w-full flex flex-col justify-center items-center">
      <div className="mt-[30%] md:mt-[15%] flex flex-col justify-center items-center">
        <h1 className="text-3xl max-w-xl px-4 md:px-6 select-none mb-6 text-center font-semibold md:text-4xl">
          <span className="bg-clip-text text-transparent bg-gradient-to-br from-[#000000] via-[#009bad] to-[#009bad]">
            Soonia
          </span>
          {language == "english"
            ? ", the perfect solution for your event"
            : ", la soluzione perfetta per il tuo evento"}
        </h1>
        {language == "english" ? (
          <p className="font-medium mb-10 text-gray-500 text-center px-10 md:px-20 md:text-xl">
            Enhance your events with Soonia and turn them into engaging and
            interactive experiences! With our web application, you can offer
            advanced features such as simultaneous translation, polls, feedback
            and lighting effects.
          </p>
        ) : (
          <p className="font-medium mb-10 text-gray-500 text-center px-10 md:px-20 md:text-xl">
            Trasforma i tuoi eventi in esperienze coinvolgenti e interattive con
            Soonia! Grazie alla nostra web app, puoi offrire funzionalità
            avanzate come: traduzione simultanea, sondaggi, feedback ed effetti
            luminosi.
          </p>
        )}
        <button
          className="inline-block rounded-2xl px-8 py-3 bg-[#009bad] text-white font-medium cursor-pointer"
          onClick={() => {
            if (props.featuresRef.current) {
              const offsetTop = props.featuresRef.current.offsetTop;
              window.scrollTo({ top: offsetTop - 100, behavior: "smooth" });
            }
          }}
        >
          {language == "english" ? "Learn more" : "Scopri di più"}
        </button>
      </div>
      <div className="w-full h-full my-20 md:mb-20 px-4">
        {language == "english" ? (
          <video
            key={language}
            loop
            autoPlay
            controls={true}
            preload="none"
            className="w-full h-full z-50 rounded-3xl"
            poster="/images/thumbeng.png"
            ref={videoRef}
          >
            <source src="/images/videoeng.mp4" type="video/mp4" />
          </video>
        ) : (
          <video
            key={language}
            loop
            autoPlay
            controls={true}
            preload="none"
            className="w-full h-full z-50 rounded-3xl"
            poster="/images/thumbita.png"
            ref={videoRef}
          >
            <source src="/images/videoita.mp4" type="video/mp4" />
          </video>
        )}
      </div>
    </section>
  );
}

export default Container;
