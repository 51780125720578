import React, {useContext, useEffect, useState} from "react";
import LanguageContext from "@/contexts/language";
import axios from "axios";
import Turnstile from "react-turnstile";

function Third(props: { emailRef: React.RefObject<HTMLDivElement> }) {
  const {language} = useContext(LanguageContext);

  const [email, setEmail] = useState("");
  const [inputStyle, setInputStyle] = useState("");
  const [buttonStyle, setButtonStyle] = useState(
    "opacity-0 transform -translate-x-1"
  );
  const [placeholder, setPlaceholder] = useState(
    language === "english" ? "Enter your email" : "Inserisci la tua email"
  );
  const [token, setToken] = useState("");

  useEffect(() => {
    if (language === "english") {
      setPlaceholder("Enter your email");
    } else {
      setPlaceholder("Inserisci la tua email");
    }
  }, [language]);

  function isValidEmail(email: string) {
    // Regular expression pattern for valid email address
    const pattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    // Use the pattern to test the email string
    return pattern.test(email);
  }

  const checkEmail = (e: any) => {
    e.preventDefault();
    if (isValidEmail(email)) {
      axios.post(
        "https://api.soonia.it/welcome-email",
        {
          email: email,
          language: language === "english" ? "en" : "it",
          "cf-turnstile-response": token,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      ).then((res) => {
        if (res.data.ok) {
          setPlaceholder("Thank you!");
          setEmail("");
          setInputStyle(
            "placeholder:!bg-clip-text placeholder:!text-transparent placeholder:!bg-gradient-to-br from-[#009bad] to-[#000000] placeholder:font-semibold"
          );
        } else {
          setPlaceholder("Something went wrong");
          setInputStyle("shake text-red-500");
          setTimeout(() => {
            setPlaceholder(language === "english" ? "Enter your email" : "Inserisci la tua email")
            setInputStyle("");
          }, 500);
        }
      }).catch(() => {
        setInputStyle("shake text-red-500");
        setTimeout(() => {
          setInputStyle("");
        }, 500);
      });
    } else {
      setInputStyle("shake text-red-500");
      setTimeout(() => {
        setInputStyle("");
      }, 500);
    }
  };

  return (
    <section
      className="max-w-[67.5rem] mx-auto mb-20 w-full px-4 py-8 text-center flex flex-col items-center"
      ref={props.emailRef}
    >
      {language === "english" ? (
        <h1 className="text-3xl font-semibold mt-10 mb-5 max-w-2xl md:text-4xl">
          Request a demo of{" "}
          <span className="bg-clip-text text-transparent bg-gradient-to-br from-[#000000] via-[#009bad] to-[#009bad]">
            soonia
          </span>
        </h1>
      ) : (
        <h1 className="text-3xl font-semibold mt-10 mb-5 max-w-2xl md:text-4xl">
          Richiedi una demo di{" "}
          <span className="bg-clip-text text-transparent bg-gradient-to-br from-[#000000] via-[#009bad] to-[#009bad]">
            soonia
          </span>
        </h1>
      )}
      {language === "english" ? (
        <p className="text-neutral-500 font-medium mb-10 max-w-3xl md:text-xl">
          Experience the power of technology to enhance your events and connect
          with the world. Get in touch with us today and revolutionize the way
          you communicate, entertain, and engage!
        </p>
      ) : (
        <p className="text-neutral-500 font-medium mb-10 max-w-3xl md:text-xl">
          Sperimenta il potere della tecnologia per migliorare i tuoi eventi e
          connetterti con il mondo. Contattaci ora e trasforma il modo in cui
          comunichi, intrattieni e coinvolgi!
        </p>
      )}
      <form
        className="flex w-full max-w-3xl p-4 mx-auto items-center h-14 backdrop-blur-xl rounded-full transition-all duration-200 ease-linear bg-white/60 shadow-sm"
        onSubmit={checkEmail}
      >
        <input
          type="text"
          placeholder={placeholder}
          className={`ml-2 pr-8 bg-transparent border-none outline-none w-full text-xl font-medium placeholder:text-neutral-300 ${inputStyle} transition-all duration-200 ease-linear`}
          value={email}
          onChange={(e) => {
            setEmail(e.target.value);
            setPlaceholder("Enter your email");
            setInputStyle("");
            if (e.target.value.length > 0) {
              setButtonStyle("opacity-100");
            } else {
              setButtonStyle("opacity-0 transform -translate-x-1");
            }
          }}
        />
        <Turnstile
          sitekey="0x4AAAAAAADgbu9NTWAz9IFs"
          onVerify={(token) => setToken(token)}
        />
        <button
          type="submit"
          className={`w-8 h-8 rounded-full bg-white shadow-sm flex justify-center items-center ${buttonStyle} transition-all duration-100 ease-linear hover:bg-gray-50`}
        >
          <svg
            width="20"
            height="20"
            fill="none"
            viewBox="0 0 24 24"
            className="transform rotate-180"
          >
            <path
              stroke="currentColor"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="1.5"
              d="M10.25 6.75L4.75 12L10.25 17.25"
            ></path>
            <path
              stroke="currentColor"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="1.5"
              d="M19.25 12H5"
            ></path>
          </svg>
        </button>
      </form>
    </section>
  );
}

export default Third;
