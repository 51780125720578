import React, { useContext } from "react";
import { AiOutlineTwitter } from "react-icons/ai";
import { FaLinkedinIn } from "react-icons/fa";
import { RiFacebookFill } from "react-icons/ri";
import LanguageContext from "@/contexts/language";
import Link from "next/link";

function Footer() {
  const { language, setEnglish, setItaliano } = useContext(LanguageContext);

  return (
    <footer className="max-w-[67.5rem] mx-auto w-full px-4 py-8 text-center flex flex-col items-center">
      <div className="flex flex-col md:flex-row w-full justify-between">
        <p className="text-center md:text-left font-medium text-gray-500 mb-10 md:mb-0">
          &#169; Soonia. Powered by{" "}
          <a href="https://suonovivo.net" className="text-black">
            {" "}
            Suonovivo
          </a>
          .
          <br />
          All rights reserved.{" "}
          <Link
            href="/terms-and-conditions"
            className="text-gray-500 hover:text-gray-700"
          >
            Terms and conditions
          </Link>
        </p>
        <div className="flex justify-center items-center gap-5">
          <a className="relative" href="https://twitter.com/soonia_official">
            <AiOutlineTwitter size={24} />
          </a>
          <a
            className="relative"
            href="https://www.linkedin.com/company/soonia/"
          >
            <FaLinkedinIn size={24} />
          </a>
          <a className="relative filter" href="https://facebook.com/soonia.it">
            <RiFacebookFill size={24} />
          </a>
        </div>
      </div>
      <div className="mt-8">
        <img
          src="/images/footerlogo.png"
          alt="footer logo"
          width={100}
          height={100}
        ></img>
      </div>
    </footer>
  );
}

export default Footer;
