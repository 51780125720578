import React, { useRef } from "react";
import styles from "../styles/page.module.css";
import Head from "next/head";
import { AppProps } from "next/app";

import Nav from "@/components/nav";
import Container from "@/components/home/container";
import Second from "@/components/home/second";
import Third from "@/components/home/third";
import Footer from "@/components/home/footer";

export default function Home({ Component, pageProps }: AppProps) {
  const featuresRef = useRef(null);
  const emailRef = useRef(null);

  return (
    <>
      <Head>
        <title>soonia</title>
        <meta
          name="description"
          content="soonia, the world's most advanced event platform"
        />
        <link rel="icon" href="/favicon.ico" />
      </Head>
      <main className={styles.main}>
        <Nav featuresRef={featuresRef} emailRef={emailRef} />
        <Container featuresRef={featuresRef} />
        <Second featuresRef={featuresRef} />
        <Third emailRef={emailRef} />
        <Footer />
      </main>
    </>
  );
}
